import { Layout } from '@components/common'
import { getConfig } from '@framework/api'
import getSiteInfo from '@framework/common/get-site-info'
import { getPageData } from '@utils/contentful/get-page-data'
import type { GetStaticPropsContext } from 'next'
import { revalidatePeriod } from '@utils/server-side-config'
import { getContentfulComponents } from '@utils/contentful/contentfulComponents/pageContent'
import { IProductPage } from '@components/product/ProductPage/ProductPage.types'
import { ContentfulPage } from 'app/_components/ContentfulPage/ContentfulPage'
import { OrganizationJsonLd } from 'next-seo'
import { NEXT_PUBLIC_DOMAIN_NAME } from '@utils/client-side-config'

export async function getStaticProps({
  params,
  preview,
  locale,
}: GetStaticPropsContext<{ slug: string }>) {
  const config = getConfig({ locale })
  const { categories } = await getSiteInfo({ config, preview })
  const pageData = await getPageData({ categories, preview })

  const contentfulPageComponents = await getContentfulComponents({
    pageSlug: '/content/' + params.slug,
    dataForParsers: {
      categories,
      preview,
    },
  })

  if (contentfulPageComponents?.slotAComponentProps?.length === 0) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      ...pageData,
      contentfulPageComponents: contentfulPageComponents || null,
    },
    revalidate: revalidatePeriod(), // In seconds
  }
}

export async function getStaticPaths() {
  return {
    paths: [
      {
        params: { slug: 'homepage' },
      },
    ],
    fallback: 'blocking',
  }
}

export default function Slug({ contentfulPageComponents }: IProductPage) {
  return (
    <>
      <OrganizationJsonLd
        name={'noissue'}
        url={`https://${NEXT_PUBLIC_DOMAIN_NAME}`}
        logo={`https://${NEXT_PUBLIC_DOMAIN_NAME}/images/logo.svg`}
        sameAs={[
          'https://www.facebook.com/noissueco/',
          'https://www.instagram.com/noissueco/',
          'https://www.pinterest.nz/noissuepackaging/',
          'https://www.youtube.com/channel/UCEt1mVOZATbEEg3SQrUm1hg',
          'https://www.linkedin.com/company/noissue/',
          'https://twitter.com/noissueco',
          'https://www.wikidata.org/wiki/Q107064571',
          'https://www.crunchbase.com/organization/noissue-co',
        ]}
      />

      {contentfulPageComponents?.slotAComponentProps?.length && (
        <ContentfulPage
          componentsProps={contentfulPageComponents?.slotAComponentProps}
          applyStaticGeneration={true}
        />
      )}
    </>
  )
}

Slug.Layout = Layout
